import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { HoursDistributionInfoTextComponent } from '../hours-distribution-info-text/hours-distribution-info-text.component';

@Component({
  selector: 'app-hours-distribution-info-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    HoursDistributionInfoTextComponent,
  ],
  templateUrl: './hours-distribution-info-dialog.component.html',
})
export class HoursDistributionInfoDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<HoursDistributionInfoDialogComponent>
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
