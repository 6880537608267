import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { EquipAssigns } from '@models/equip-assigns';

export const getEquipAssignsAction = createAction(
  '[Equip assigns Collection Effect] AssignsForEquipmentQuery',
  contextAwareProps<{
    payload: { ctrId: number; equipmentId: number };
  }>()
);

export const getEquipAssignsActionSuccess = createAction(
  '[Equip assigns Collection Effect] AssignsForEquipmentQuery SUCCESS',
  contextAwareProps<{ payload: EquipAssigns }>()
);

export const getEquipAssignsActionFailure = createAction(
  '[Equip assigns Collection Effect] AssignsForEquipmentQuery FAILURE',
  contextAwareProps<{
    payload: { ctrId: number; equipmentId: number };
  }>()
);
