<p>
  By default hours matrix for CTR is obtained by evenly distributing total
  number of hours over each time period, between CTR start and end dates. This
  means that the hours each month should be as equal as possible. But there are
  some corner cases where this is not possible. For example:
</p>
<ul>
  <li>
    The minimal number of hours per month is 0.1 FTE . This means that if you
    have 1 FTE and 11 months to distribute the hours, the can't distribute the
    hours evenly. All hours will go to the first month.
  </li>
  <li>
    Due to the rounding issues the last month might have a few hours more or
    less than others. It's due to the fact sometimes the hours can't be divided
    evenly. For example we have 81 hours to split between 4 months and 1 FTE
    equals 147 hours. Then we have 81/147 = 0.551 FTE to split. That gives us
    0.13 (0.1 rounded) FTE per month. So we need to put 0.3 left FTE in the last
    month.
  </li>
</ul>
<p>
  For more details please visit
  <a
    target="_blank"
    rel="noopener"
    href="https://digicorner.sharepoint.com/sites/TheWell/SitePages/WF-3-Build,-Download-CTR.aspx#4.7-calendarization"
  >
    User Manual on The Well page.</a
  >
</p>
