<h2 mat-dialog-title>Distribute hours evenly - explanation</h2>
<mat-dialog-content data-test="distribute-evenly-explanation">
  <app-hours-distribution-info-text></app-hours-distribution-info-text>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button
    mat-raised-button
    color="accent"
    cdkFocusInitial
    (click)="closeDialog()"
    data-test="distribute-evenly-save-button"
  >
    Close
  </button>
</mat-dialog-actions>
