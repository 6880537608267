import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-table-cell-input',
  templateUrl: './table-cell-input.component.html',
  styleUrls: ['./table-cell-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableCellInputComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellInputComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges
{
  @Input() controlId: string;
  @Input() label: string;
  @Input() type: 'number' | 'text' = 'text';
  disabled: boolean;
  @Input() maxLength: number;
  @Input() maxDigitsCount: number = null;
  @Input() counterMinimumValue = 0;
  @Input() counterForFocusedOnly: boolean;
  @Input() debounce: number;
  @Input() error: boolean;
  @Input() integer = true;
  @Input() fitHeight = true;
  @Input() placeholder = '';
  @Input() suffix: string;
  @Input() showZero = false;
  @Output() change = new EventEmitter<string>();
  @Output() inputEvent = new EventEmitter<string>();

  private readonly destroyRef = inject(DestroyRef);
  inputEvent$ = new Subject<string>();
  value: string | number;
  @Input() additionalInputClasses: { [key: string]: boolean };

  @ViewChild('input', { static: true }) inputElement: ElementRef;

  onChangeHandler = (value) => {};
  onTouchedHandler = () => {};

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.inputEvent$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(this.debounce))
      .subscribe((inputValue) => {
        this.value = inputValue;
        this.onChangeHandler(inputValue);
        this.inputEvent.emit(inputValue);
        this.additionalInputClasses = this.getAdditionalClasses();
        this.cdr.markForCheck();
      });

    // handle updates on input blur and really long no input debounce

    this.inputEvent$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(5000))
      .subscribe((inputValue) => {
        this.onChange(inputValue);
      });
  }

  ngAfterViewInit() {
    this.additionalInputClasses = this.getAdditionalClasses();
  }

  ngOnChanges(changes: SimpleChanges) {
    const inputClassPotentialUpdate =
      changes.label ||
      changes.type ||
      changes.maxLength ||
      changes.counterMinimumValue ||
      changes.fitHeight;
    if (inputClassPotentialUpdate) {
      this.additionalInputClasses = this.getAdditionalClasses();
    }
  }

  getAdditionalClasses() {
    return {
      'with-label': !!this.label,
      'number-input': this.type === 'number',
      'with-suffix': !!this.suffix,
      'with-numbers-input-guard':
        this.maxLength &&
        this.value?.toString().length >= this.counterMinimumValue,
      'fit-height': !!this.fitHeight,
    };
  }

  onInput(inputValue: string): void {
    if (this.value != inputValue && inputValue !== '-') {
      this.additionalInputClasses = this.getAdditionalClasses();
      this.inputEvent$.next(inputValue);
    }
  }

  onChange(inputValue: string): void {
    if (this.value != inputValue) {
      this.value = inputValue;
      this.change.emit(inputValue);
      this.onChangeHandler(inputValue);
    }
  }

  onFocus(event): void {
    const boundRect = event.currentTarget.getBoundingClientRect();
    const safePadding = 80;

    const needsScrolling =
      boundRect.bottom > window.visualViewport.height - safePadding ||
      boundRect.top < window.visualViewport.height / 2 ||
      boundRect.left < window.visualViewport.width / 2 ||
      boundRect.right > window.visualViewport.width - safePadding;

    if (needsScrolling) {
      event.currentTarget.scrollIntoView({ block: 'center' });
    }
  }

  writeValue(value: string | number) {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: any) {
    this.onChangeHandler = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouchedHandler = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
