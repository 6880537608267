import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { HoursDistributionInfoTextComponent } from '../calendarization-table-roles/hours-distribution-info-text/hours-distribution-info-text.component';
import { RolesCalendarizationActions } from '../store/roles-calendarization.actions';
export interface DistributeWorkloadEvenlyDialogData {
  scenarioCtrId: number;
}

@Component({
  selector: 'app-distribute-workload-evenly-dialog',
  templateUrl: './distribute-workload-evenly-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    HoursDistributionInfoTextComponent,
  ],
})
export class DistributeWorkloadEvenlyDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: DistributeWorkloadEvenlyDialogData,
    public readonly dialogRef: MatDialogRef<DistributeWorkloadEvenlyDialogComponent>,
    private readonly store: Store,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onYes(): void {
    this.googleAnalyticsService.event(
      'click_save_distribute_ctr_hours_evenly',
      'calendarization'
    );
    this.store.dispatch(
      RolesCalendarizationActions.distributeEvenly({
        scenarioCtrId: this.data.scenarioCtrId,
      })
    );
    this.dialogRef.close();
  }
}
