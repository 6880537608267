<!--The content below is only a placeholder and can be replaced.-->
<div class="page">
  <div class="main-content">
    <div
      class="environment-bar"
      *ngIf="environmentColor"
      style="background-color: {{ environmentColor }}"
    ></div>
    <app-header></app-header>

    <mat-sidenav-container class="main-container" hasBackdrop="false">
      <mat-sidenav
        *ngIf="sidenavVisible$ | async"
        [autoFocus]="false"
        [opened]="layoutService.sidenavToggle$ | async"
        disableClose="true"
        mode="side"
        position="start"
      >
        <app-sidenav></app-sidenav>
      </mat-sidenav>
      <mat-sidenav-content (scroll)="scrollHandler($event)">
        <mat-sidenav-container>
          <mat-sidenav-content>
            <div class="float-left-border">
              <div
                *ngIf="sidenavVisible$ | async"
                (click)="toggleSidenav()"
                (keydown.enter)="toggleSidenav()"
                class="toggle-sidenav"
                data-test="toggle-sidenav-button"
              >
                <mat-icon
                  class="arrow-toggle"
                  [class.opened]="layoutService.sidenavToggle$ | async"
                  >arrow_forward
                </mat-icon>
              </div>
            </div>
            <div class="body">
              <router-outlet></router-outlet>
            </div>
          </mat-sidenav-content>

          <mat-sidenav
            class="event-log-sidenav"
            *ngIf="activityLogVisible$ | async"
            [autoFocus]="false"
            [fixedInViewport]="true"
            [fixedTopGap]="60"
            [opened]="activitiesOpened"
            (closedStart)="activitiesOpened = false"
            mode="over"
            position="end"
          >
            <mat-icon
              class="close-side-nav"
              (click)="activitiesOpened = false"
              (keydown.enter)="activitiesOpened = false"
              >close</mat-icon
            >
            <app-event-log></app-event-log>
          </mat-sidenav>
        </mat-sidenav-container>
      </mat-sidenav-content>

      <mat-sidenav
        class="right-sidenav"
        *ngIf="(activityLogVisible$ | async) && isActivityLogFeatureEnabled"
        [autoFocus]="false"
        [fixedInViewport]="true"
        [fixedTopGap]="60"
        [opened]="!activitiesOpened"
        mode="side"
        position="end"
      >
        <mat-icon
          (click)="activitiesOpened = !activitiesOpened"
          (keydown.enter)="activitiesOpened = !activitiesOpened"
          >notifications</mat-icon
        >
      </mat-sidenav>
    </mat-sidenav-container>

    <div class="spinner-display" *ngIf="showSpinner$ | async">
      <mat-spinner color="primary"></mat-spinner>
    </div>

    <app-live-save-snackbar></app-live-save-snackbar>
  </div>
</div>
